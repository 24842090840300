import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { nanolikeRuleType } from 'shared/model/rule.model';
import { createRule, fetchRule, updateRule } from 'shared/reducers/rulesSlice';
import { fetchUsers } from 'shared/reducers/usersSlice';
import { usePrevious } from 'shared/utils/react-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import RuleForm from './ruleForm';

export interface IRuleFormResponse {
  name: string;
  resourcetype: nanolikeRuleType;
  device_group: ILabelValueOption[];
  user_group: ILabelValueOption[];
  quiet_period_in_h: number;
  min_days_for_maintenance?: number;
  days_after_delivery?: number;
  inactive_days?: number;
  is_at_customer_location?: boolean;
  is_at_factory?: boolean;
  lost_days?: number;
}

const CreateOrEditRule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ rules }: IRootState) => rules.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  const users = useSelector(({ users }: IRootState) => users.users);
  const loadingUsers = useSelector(({ users }: IRootState) => users.loading);
  const rule = useSelector(({ rules }: IRootState) => rules.rule);
  const loading = useSelector(({ rules }: IRootState) => rules.loading);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const groupGroup = t('group');
  const deviceGroup = t('device', { count: 100 });
  const userGroup = t('user', { count: 100 });

  useEffect(() => {
    if (!isNew && id) {
      dispatch(fetchRule(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsers());
    }
  }, [users.length, dispatch]);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess === true) {
      history.push('/rules');
    }
  }, [history, previousUpdateSuccess, updateSuccess]);

  const onSubmit = (responses: IRuleFormResponse) => {
    const ruleFormResponse = getRuleFormResponse(responses, groupGroup, deviceGroup, userGroup);

    if (isNew) {
      dispatch(createRule(ruleFormResponse));
    } else {
      const ruleToUpdate = {
        id: rule?.id,
        ...ruleFormResponse
      };
      dispatch(updateRule(ruleToUpdate));
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3}>
        {loading || loadingDevices || loadingUsers ? (
          <Box p={4}>
            <Loading />
          </Box>
        ) : (
          <>{(isNew || rule) && <RuleForm onSubmit={onSubmit} />}</>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditRule;

const getRuleFormResponse = (
  responses: IRuleFormResponse,
  groupGroup: string,
  deviceGroup: string,
  userGroup: string
) => {
  const rule: any = {
    ...responses,
    is_enabled: true
  };
  rule.quiet_period_in_h = responses.quiet_period_in_h * 24;

  // thank you TS ...
  // RULE_INTEGER_FIELDS.forEach(key => {
  //   if (responses[key]) rule[key] = parseInt(pesponses[key], 10);
  // });
  if (responses.days_after_delivery)
    rule.days_after_delivery = Number(responses.days_after_delivery);
  if (responses.inactive_days) rule.inactive_days = Number(responses.inactive_days);
  if (responses.lost_days) rule.lost_days = Number(responses.lost_days);
  if (responses.min_days_for_maintenance)
    rule.min_days_for_maintenance = Number(responses.min_days_for_maintenance);

  const recipients_groups: string[] = [];
  const recipients_users: string[] = [];
  responses.user_group.forEach(current => {
    if (current.group === groupGroup) {
      recipients_groups.push(current.value);
    } else if (current.group === userGroup) {
      recipients_users.push(current.value);
    }
  });
  rule.recipients_groups = recipients_groups;
  rule.recipients_users = recipients_users;
  delete rule.user_group;

  const watched_devices: string[] = [];
  const watched_groups: string[] = [];
  responses.device_group.forEach(current => {
    if (current.group === groupGroup) {
      watched_groups.push(current.value);
    } else if (current.group === deviceGroup) {
      watched_devices.push(current.value);
    }
  });
  rule.watched_devices = watched_devices;
  rule.watched_groups = watched_groups;
  delete rule.device_group;

  return rule;
};
