import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import config from 'config/config';
import { getEnvApiUrl, getEnvWorkspace } from 'config/env';
import { AppThunk } from 'config/store';
import { IMe, IUser } from 'shared/model/user.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { hideChat, setUserForChat, showChat } from 'shared/utils/chat.utils';
import Storage, { JWT_TOKEN_ACCESS_KEY, JWT_TOKEN_REFRESH_KEY } from 'shared/utils/storage-utils';
import { errorNotification, successNotification } from './notifierSlice';
import { fetchMe } from './usersSlice';
import { fetchWorkspace, resetWorkspace } from './workspaceSlice';

export interface ICredential {
  username: string;
  password: string;
}

interface ILoginResponse {
  refresh: string;
  access: string;
}

const initialState = {
  loading: false,
  updating: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false,
  resetSuccess: false,
  changePwdSuccess: false,
  me: null as IUser | IMe | null,
  errorMessage: '' as string,
  sessionHasBeenFetched: true,
  jwtToken: ''
};

export type AuthenticationState = typeof initialState;

//argument is a proxy of the state created by redux toolkit
const setInitial = (proxyState: any) => {
  proxyState.loading = false;
  proxyState.updating = false;
  proxyState.isAuthenticated = false;
  proxyState.loginSuccess = false;
  proxyState.loginError = false;
  proxyState.resetSuccess = false;
  proxyState.me = null;
  proxyState.errorMessage = '';
  proxyState.changePwdSuccess = false;
  proxyState.sessionHasBeenFetched = true;
  proxyState.jwtToken = '';
  proxyState.rights = [];
  axios.defaults.headers.common.Authorization = null;
};

export const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginStart: state => {
      state.loading = true;
    },
    loginFailed: (state, action: PayloadAction<string>) => {
      setInitial(state);
      state.loginError = true;
      state.errorMessage = action.payload;
      Storage.session.remove(JWT_TOKEN_ACCESS_KEY);
      Storage.session.remove(JWT_TOKEN_REFRESH_KEY);
    },
    loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
      const accessToken = action.payload.access;

      state.loading = false;
      state.loginError = false;
      state.loginSuccess = true;
      state.errorMessage = '';
      state.jwtToken = accessToken;
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common.WORKSPACE = getEnvWorkspace();
      Storage.session.set(JWT_TOKEN_ACCESS_KEY, accessToken);
      Storage.session.set(JWT_TOKEN_REFRESH_KEY, action.payload.refresh);
    },
    authenticationSuccess: (state, action: PayloadAction<IMe>) => {
      state.me = action.payload;
      state.isAuthenticated = true;
    },
    authenticationFailed: state => {
      state.isAuthenticated = false;
    },
    logoutAction: state => {
      setInitial(state);
      clearInterval(refreshTokenInterval);
      Storage.session.clear();
    },
    resetStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.resetSuccess = false;
    },
    resetSuccess: state => {
      state.updating = false;
      state.resetSuccess = true;
    },
    resetFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.resetSuccess = false;
      state.errorMessage = action.payload;
    },
    changePasswordStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.changePwdSuccess = false;
    },
    changePasswordFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.changePwdSuccess = false;
      state.errorMessage = action.payload;
    },
    changePasswordSuccess: state => {
      state.updating = false;
      state.changePwdSuccess = true;
    }
  }
});

export default slice.reducer;

//Actions
const {
  loginStart,
  loginSuccess,
  loginFailed,
  authenticationFailed,
  resetStart,
  resetFailed,
  resetSuccess,
  changePasswordStart,
  changePasswordFailed,
  changePasswordSuccess,
  logoutAction
} = slice.actions;
export const { authenticationSuccess } = slice.actions;

const apiUrl = getEnvApiUrl();

export const login =
  (credential: ICredential): AppThunk =>
  async dispatch => {
    try {
      dispatch(loginStart());
      const response: AxiosResponse = await axios.post(`${apiUrl}/token`, credential);
      await dispatch(loginSuccess(response.data));
      startRefreshToken(dispatch);
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(loginFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const loginByToken =
  (refresh: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(loginStart());
      const response: AxiosResponse = await axios.post(`${apiUrl}/token/refresh`, { refresh });
      await dispatch(loginSuccess(response.data));
      startRefreshToken(dispatch);
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(loginFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const authenticate = (): AppThunk => async (dispatch, getState) => {
  await Promise.all([dispatch(fetchWorkspace()), dispatch(fetchMe())]);
  const me = getState().users.me;
  if (me) {
    setUserForChat(me);
    dispatch(authenticationSuccess(me));
  } else {
    dispatch(authenticationFailed());
  }
};

export const resetPassword =
  (email: string, lang: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(resetStart());
      await axios.post(
        `${apiUrl}/reset-password`,
        { email: email.toLowerCase(), lang },
        {
          headers: {
            WORKSPACE: getEnvWorkspace()
          }
        }
      );
      dispatch(resetSuccess());
      dispatch(successNotification('send_invite_reset_password_success'));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(resetFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const changePassword =
  (userId: string, new_password: string, old_password: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(changePasswordStart());
      await axios.patch(`${apiUrl}/users/${userId}/`, {
        new_password,
        old_password
      });
      dispatch(changePasswordSuccess());
      dispatch(successNotification('edit_profile_success'));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(changePasswordFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

let refreshTokenInterval: any = null;
const startRefreshToken = (dispatch: any) => {
  clearInterval(refreshTokenInterval);
  refreshTokenInterval = setInterval(() => {
    const refreshToken = Storage.session.get(JWT_TOKEN_REFRESH_KEY);
    dispatch(loginByToken(refreshToken));
  }, config.refreshTokenTimeout);
};

export const logout = (): AppThunk => async (dispatch, getState) => {
  await dispatch(logoutAction());
  await dispatch(resetWorkspace());
  if (getState().settings.settings?.config_activated) {
    hideChat();
  } else {
    showChat();
  }
};
