import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmDeleteProps {
  title?: string;
  message?: string;
  size?: 'small' | 'medium' | 'large';
  btnText?: string;
  onConfirm: (objectToReturn?: any) => void;
  onCancel?: () => void;
  noIcon?: boolean;
  objectToReturn: any[];
  btnVariant?: 'text' | 'outlined' | 'contained';
  iconFontSize?: 'inherit' | 'medium' | 'small' | 'large';
}

const ConfirmDelete = (props: IConfirmDeleteProps) => {
  const {
    title,
    message,
    onConfirm,
    objectToReturn,
    size = 'medium',
    btnVariant = 'contained',
    iconFontSize = 'medium',
    btnText,
    noIcon = false
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleConfirm = () => {
    onConfirm(objectToReturn);
    setOpen(false);
  };

  const finalTitle = title ? title : t('defaultTitle');
  const finalMessage = message
    ? message
    : objectToReturn.length === 1
    ? t('defaultSingleMessage')
    : t('defaultMultipleMessage', { nb: objectToReturn.length });

  return (
    <>
      <Button variant={btnVariant} color="secondary" onClick={handleClickOpen} size={size}>
        {!noIcon && <DeleteIcon fontSize={iconFontSize} />}
        {btnText}
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle>{finalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{finalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="default"
            startIcon={<CancelIcon />}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            autoFocus
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
