import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '90vh'
    },
    progress: {
      width: '150px',
      height: '8px'
    },
    colorPrimary: {
      backgroundColor: '#D6D6D6'
    },
    barColorPrimary: {
      background: '#808080'
    }
  })
);

const LoadingSettings = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item sm={4}>
        <LinearProgress
          className={classes.progress}
          classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
        />
      </Grid>
    </Grid>
  );
};

export default LoadingSettings;
