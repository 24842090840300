import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

type SelectMaxProps = { isActive?: boolean } & Partial<TextFieldProps>;

const SelectMax = (props: SelectMaxProps) => {
  const { isActive = true, inputProps = {}, ...rest } = props;
  const { t } = useTranslation();
  const form = useFormContext();
  const datatype = form.watch<string, string>('data_type');

  return (
    <TextField
      margin="dense"
      id="max_value"
      fullWidth
      label={t('max_val')}
      type="number"
      name="max_value"
      inputProps={{
        step: 'any',
        min: 0,
        ...inputProps
      }}
      inputRef={form.register({
        validate: value => {
          if (isActive) {
            if (value.length === 0) {
              return <Trans i18nKey="required_field">Required Field</Trans>;
            } else if (value < 0) {
              return <Trans i18nKey="positive_integer_value">Required Field</Trans>;
            }
            if (
              value?.length > 0 &&
              (datatype.value === 'level_t' || datatype.value === 'missingWeight')
            ) {
              if (!/^\d+(\.\d{1})?$/.test(value))
                return <Trans i18nKey="alert_form_one_decimal_error">Required Field</Trans>;
            }
            if (
              value?.length > 0 &&
              datatype.value !== 'level_t' &&
              datatype.value !== 'missingWeight'
            ) {
              if (!Number.isInteger(Number(value))) {
                return <Trans i18nKey="alert_form_min_or_max_integer_error">Required Field</Trans>;
              }
            }
          }
          return true;
        }
      })}
      error={form.errors.max_value ? true : false}
      helperText={form.errors.max_value && form.errors.max_value.message}
      {...rest}
    />
  );
};

export default SelectMax;
