import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Button, Dialog, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { IRootState } from 'config/store';
import { IDevice } from 'shared/model/device.model';
import { updateDevice } from 'shared/reducers/devicesSlice';
import DeviceForm, { IDeviceFormResponse } from './DeviceForm';

export interface IEditDeviceDialogProps {
  device: IDevice;
  onSuccess?: Function;
}

const EditDeviceDialog: React.FC<IEditDeviceDialogProps> = ({ device, onSuccess }) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ devices }: IRootState) => devices.updateSuccess);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true && open) {
      if (onSuccess) {
        onSuccess();
      }
      setOpen(false);
    }
  }, [onSuccess, open, updateSuccess]);

  const handleSubmit = (values: IDeviceFormResponse) => {
    const group_ids = values?.groups?.map(aGroup => aGroup.value as string);

    const payload: Partial<IDevice> = {
      device_id: device.device_id,
      group_ids,
      device_name: values.device_name,
      device_description: values.device_description,
      device_install_date: values.device_install_date,
      device_fabrication_date: values.device_fabrication_date,
      device_next_maintenance_date: values.device_next_maintenance_date,
      device_content_id: values.device_content?.value ?? null,
      galileou_enabled: values.galileou_enabled
    };
    if (!device.is_silo) delete payload.device_content_id;
    dispatch(updateDevice(payload));
  };

  return (
    <>
      <Tooltip title={<Trans i18nKey="edit_device"></Trans>}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog} maxWidth="md">
        <DeviceForm device={device} onSubmit={handleSubmit} onCancel={toggleDialog} />
      </Dialog>
    </>
  );
};

export default EditDeviceDialog;
