import { TextField } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

export interface ISelectTimeThresholdProps {
  isActive: boolean;
}

const SelectTimeThreshold = (props: ISelectTimeThresholdProps) => {
  const { isActive } = props;
  const { t } = useTranslation();
  const form = useFormContext();

  return (
    <TextField
      margin="dense"
      id="max_value"
      fullWidth
      label={t('time_threshold')}
      type="number"
      name="max_value"
      inputProps={inputProps}
      inputRef={form.register({
        validate: value => {
          if (isActive) {
            if (value.length === 0) {
              return <Trans i18nKey="required_field">Required Field</Trans>;
            } else if (value <= 0 || !Number.isInteger(Number(value))) {
              return <Trans i18nKey="positive_integer_value">Positive integer</Trans>;
            }
          }
          return true;
        }
      })}
      error={form.errors.max_value ? true : false}
      helperText={form.errors.max_value && form.errors.max_value.message}
    />
  );
};

export default SelectTimeThreshold;
