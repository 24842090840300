import { IRootState } from 'config/store';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AutoOrderHistory } from 'shared/model/autoOrder.model';
import { fetchAutoOrdersHistory } from 'shared/reducers/autoOrdersSlice';
import { APP_LOCAL_DATE_FORMAT, APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import { Box, createStyles, makeStyles, Theme, Tooltip, useTheme } from '@material-ui/core';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import { ILabelValueOption } from 'shared/utils/select-utils';
import DeviceFilter from 'shared/widgets/devices/deviceFilter';
import { DatePicker } from '@material-ui/pickers';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import ErrorPng from 'shared/icons/error.png';
import StatusFilter from 'shared/widgets/autoorders/statusFilter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignContent: 'flex-end'
      },
      marginRight: theme.spacing(2)
    },
    datePicker: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: '10rem',
        marginLeft: theme.spacing(6)
      }
    }
  })
);

const AutoOrdersMonitoring = () => {
  const { t } = useTranslation();
  const loading = useSelector(({ autoOrder }: IRootState) => autoOrder.loading);
  const dispatch = useDispatch();
  const autoOrderHistory = useSelector(({ autoOrder }: IRootState) => autoOrder.autoOrderHistory);
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const truckQty = settings.find(s => s.key === 'truck_quantity')?.value ?? 31;
  const classes = useStyles();
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);
  const [devicesFilter, setDevicesFilter] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('null');
  const [startDate, setStartDate] = useState(moment().subtract(2, 'years').startOf('day'));
  const [orderToSearch, setOrderToSearch] = useState<string>('');
  const theme = useTheme();
  const columns: IDataTableColumn<AutoOrderHistory>[] = useMemo(() => {
    return [
      {
        selector: 'created_at',
        name: t('created_at'),
        sortable: true,
        grow: 2,
        format: (row: any) => {
          return formatDate(row.created_at, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'orderNo',
        name: t('order_number'),
        sortable: true,
        grow: 1,
        format: row => {
          return row.code !== 10000 && row.orderNo === null ? (
            <Tooltip title={t('autoorder_not_created')} placement="top">
              <Box display="flex" alignItems="center">
                <img
                  src={ErrorPng}
                  style={{
                    width: '24px',
                    height: '24px'
                  }}
                  alt=""
                />
              </Box>
            </Tooltip>
          ) : (
            row.orderNo
          );
        }
      },
      {
        selector: 'quantity',
        name: t('quantity'),
        sortable: true,
        grow: 1,
        format: () => (
          <Box display="flex" alignItems="center">
            {truckQty}
          </Box>
        )
      },
      {
        selector: 'plant',
        name: t('plant'),
        sortable: true,
        grow: 3.5,
        format: row => {
          const siteName = devices.find(
            device => device.device_reference === row.idDevice
          )?.farm_name;
          return (
            <Box display="flex" alignItems="center">
              {siteName}
            </Box>
          );
        }
      },
      {
        id: 'siloName',
        selector: 'idDevice',
        name: t('silo_plural'),
        sortable: true,
        grow: 2,
        format: row => {
          const deviceName = devices.find(
            device => device.device_reference === row.idDevice
          )?.device_name;
          return (
            <Box display="flex" alignItems="center">
              {deviceName}
            </Box>
          );
        }
      }
    ];
  }, [t, devices, truckQty]);

  useEffect(() => {
    dispatch(fetchAutoOrdersHistory(startDate, orderToSearch));
  }, [dispatch, startDate, orderToSearch]);

  const title = React.useMemo(() => {
    const onChange = (search: string) => {
      console.log('onChange', search);
      setOrderToSearch(search);
    };
    const onGroupsChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setGroupsFilter(filter);
    };
    const onDeviceChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setDevicesFilter(filter);
    };
    const onStatusChange = (event: React.ChangeEvent<any>) => {
      setStatus(event.target.value);
    };
    return (
      <Box className={classes.root}>
        <TitleWithSearchField
          title={t('auto_orders_history')}
          placeholder={t('auto_orders_history_search')}
          onChange={onChange}
          autoFocus
          debounceWait={300}
          loading={loading}
        />
        <DeviceFilter onChange={onDeviceChange} />
        <GroupFilter onChange={onGroupsChange} />
        <StatusFilter status={status} onChange={onStatusChange} />
        <DatePicker
          disableToolbar
          value={startDate}
          onChange={(value: any) => setStartDate(value)}
          format={APP_LOCAL_DATE_FORMAT}
          disableFuture
          margin="none"
          variant="inline"
          style={{ marginBottom: 8 }}
          className={classes.datePicker}
        />
      </Box>
    );
  }, [t, classes, loading, startDate, status]);

  const filterDevices = (autoorder: AutoOrderHistory) => {
    if (devicesFilter.length > 0) {
      const device = devices.find(device => device.device_reference === autoorder.idDevice);
      return device && device.device_name && devicesFilter.includes(device.device_id);
    }
    if (groupsFilter.length > 0) {
      const device = devices.find(device => device.device_reference === autoorder.idDevice);
      return device && device.farm_id && groupsFilter.includes(device.farm_id);
    }
    return true;
  };

  const filterStatus = (autoorder: AutoOrderHistory) => {
    if (status === 'null') return true;
    if (status === 'KO') return autoorder.code !== 10000 && autoorder.orderNo === null;
    if (status === 'OK') return autoorder.code === 10000 && autoorder.orderNo !== null;
    return true;
  };

  const filteredData = autoOrderHistory.filter(
    autoorder => filterDevices(autoorder) && filterStatus(autoorder)
  );

  const conditionalRowStyles = [
    {
      when: (row: AutoOrderHistory) => row.code !== 10000 && row.orderNo === null,
      style: {
        backgroundColor: theme.palette.error.light
      }
    }
  ];

  return (
    <Box p={1}>
      <DataTable
        title={title}
        defaultSortField="created_at"
        defaultSortAsc={false}
        columns={columns}
        data={filteredData}
        progressPending={loading}
        conditionalRowStyles={conditionalRowStyles}
      />
    </Box>
  );
};

export default AutoOrdersMonitoring;
