import { InputAdornment, IconButton, TextField, Dialog } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { IRootState } from 'config/store';
import CreateOrEditGroup from 'modules/groupsDevices/form/createOrEditGroup';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import validateRequired from 'shared/widgets/form/validateRequired';

const EditShiptoSoldto = ({
  groupId,
  isActiveStep
}: {
  groupId: string;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { register, errors, triggerValidation } = useFormContext();
  const group = useSelector(({ group }: IRootState) =>
    group.groups.find(g => g.group_id === groupId)
  );
  const shipTo = group?.client_poi_id;
  const soldTo = group?.sold_to;
  const [isEditingGroup, setEditingGroup] = React.useState(false);
  const editButton = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => {
          setEditingGroup(true);
        }}
      >
        {<Edit />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <TextField
        // Use a key because when changing group,
        // sometimes the displayed value does not change
        key={`shipTo-${groupId}`}
        label={t('ship_to')}
        value={shipTo}
        disabled
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        placeholder="None"
        InputProps={{
          endAdornment: !shipTo && editButton
        }}
        name="shipTo"
        inputRef={register({
          validate: isActiveStep ? validateRequired : undefined
        })}
        error={errors.shipTo ? true : false}
        helperText={errors?.shipTo?.message}
      />
      <TextField
        key={`soldTo-${groupId}`}
        label={t('sold_to')}
        value={soldTo}
        disabled
        required
        fullWidth
        placeholder="None"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: !soldTo && editButton
        }}
        name="soldTo"
        inputRef={register({
          validate: isActiveStep ? validateRequired : undefined
        })}
        error={errors.soldTo ? true : false}
        helperText={errors?.soldTo?.message}
      />
      {isEditingGroup && (
        <Dialog
          open={isEditingGroup}
          onClose={() => {
            setEditingGroup(false);
            triggerValidation();
          }}
          fullScreen
        >
          <CreateOrEditGroup
            id={groupId}
            editShiptoSoldtoMode
            onClose={() => {
              setEditingGroup(false);
              triggerValidation();
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditShiptoSoldto;
