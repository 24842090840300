import { Validate } from 'react-hook-form';

/**
 * Combines multiple React Hook Form validate functions into a single validate function.
 *
 * @param validates Array of validate functions, ignoring nullish values for convenience
 * @returns A validator for the combined validate functions
 */
function combineValidates<T extends unknown>(
  // destructure the array of arguments
  ...validates: (Validate | null | undefined | false | 0 | '')[]
) {
  return (value: T) => {
    for (const validate of validates) {
      if (!validate) continue;

      const result = validate(value);
      if (result !== true) {
        return result;
      }
    }
    return true;
  };
}

export default combineValidates;
