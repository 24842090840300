import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPng from 'shared/icons/error.png';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    statusFilter: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: '10rem',
        marginLeft: theme.spacing(6)
      }
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

interface IStatusFilterProps {
  status: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const StatusFilter = (props: IStatusFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { status, onChange } = props;
  return (
    <Select
      labelId="device-battery-status-select-filter"
      value={status}
      onChange={onChange}
      className={classes.statusFilter}
    >
      <MenuItem value="null">
        <span className={classes.empty}>{t('rules_status')}</span>
      </MenuItem>
      <MenuItem value="KO">
        <div className={classes.menuItem}>
          <Box display="flex" alignItems="center">
            <img
              src={ErrorPng}
              style={{
                width: '24px',
                height: '24px'
              }}
              alt=""
            />
          </Box>
        </div>
      </MenuItem>

      <MenuItem value="OK">
        <div className={classes.menuItem}> </div>
      </MenuItem>
    </Select>
  );
};

export default StatusFilter;
