import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, TextField } from '@material-ui/core';
import { DeviceAutoOrder, GroupAutoOrder } from 'shared/model/autoOrder.model';
import { AutoOrderFormResponse } from './AutoOrderForm';
import AutoSettingsButton from './auto-settings/AutoSettingsButton';
import StepContainer from 'shared/widgets/form/StepContainer';
import AutoOrderDeviceConfiguration from './AutoOrderDeviceConfiguration';
import { sortBy } from 'lodash';

export const TRUCK_QUANTITY_TONS = 31;

const AutoOrderFormTriggeringSetup = ({
  isActiveStep,
  editGroupAutoOrder,
  isNew
}: {
  isNew: boolean;
  editGroupAutoOrder?: GroupAutoOrder;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { watch, register } = useFormContext<AutoOrderFormResponse>();
  const groupId = watch('groupId')?.value;
  const shipTo = watch('shipTo');
  const soldTo = watch('soldTo');
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const group = groups.find(g => g.group_id === groupId);

  const selectedDeviceIds: string[] = watch('selectedDeviceIds');
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const selectedDevices = devices.filter(d => selectedDeviceIds.includes(d.device_id));

  useEffect(() => {
    register('autoOrders', {
      validate: (autoOrders: AutoOrderFormResponse['autoOrders']) => {
        if (!isActiveStep) return true;
        // All devices have at least one trigger
        const missingTrigger =
          selectedDeviceIds.some(id => !Object.keys(autoOrders).includes(id)) ||
          Object.values(autoOrders).some(deviceAutoOrders => deviceAutoOrders.length === 0);
        return !missingTrigger;
      }
    });
  }, [register, selectedDeviceIds, isActiveStep]);

  if (!group) return null;

  return (
    <StepContainer isActiveStep={isActiveStep}>
      <Box p={2}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <TextField
              label={t('plant')}
              value={group?.group_name ?? ''}
              disabled
              placeholder="None"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item>
            <TextField
              label={t('ship_to')}
              value={shipTo || ''}
              disabled
              InputLabelProps={{ shrink: true }}
              placeholder="None"
            />
          </Grid>

          <Grid item>
            <TextField
              label={t('sold_to')}
              value={soldTo || ''}
              disabled
              placeholder="None"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="flex-end"></Box>

      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        {selectedDevices.length > 0 && (
          <Box alignSelf="start" pr={2} pl={2}>
            <AutoSettingsButton devices={selectedDevices} isNew={isNew} />
          </Box>
        )}

        {sortBy(selectedDevices, device => device.device_name).map(device => (
          <AutoOrderDeviceConfiguration
            key={device.device_id}
            device={device}
            group={group}
            editDeviceAutoOrder={editGroupAutoOrder?.device_auto_orders.find(
              (dao: DeviceAutoOrder) => dao.device_id === device.device_id
            )}
          />
        ))}
      </Box>
    </StepContainer>
  );
};

export default AutoOrderFormTriggeringSetup;
