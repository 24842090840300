import React from 'react';
import { Validate } from 'react-hook-form';
import { Trans } from 'react-i18next';

/**
 * React Hook Form validator for required fields.
 */
const validateRequired: Validate = (value: unknown) => {
  // Check whether value has a length property, for multi-select for example
  const hasLength = value && typeof value === 'object' && 'length' in value;
  const isEmpty = hasLength ? value.length === 0 : !value;

  if (isEmpty) {
    return <Trans i18nKey="required_field">Required Field</Trans>;
  } else {
    return true;
  }
};

export default validateRequired;
