import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { IProduct } from 'shared/model/product.model';
import useProductDialog from './useProductDialog';

const CreateOrEditProductButton: React.FC<{
  product?: IProduct;
  onSuccess?: () => void;
}> = ({ product, onSuccess }) => {
  const isNew = !product?.id;
  const { dialog, openDialog } = useProductDialog({ product, onSuccess });

  return (
    <>
      <Button variant="contained" color="primary" size="small" onClick={() => openDialog(true)}>
        {isNew ? <AddIcon /> : <EditIcon />}
      </Button>
      {dialog}
    </>
  );
};

export default CreateOrEditProductButton;
