import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useConfigTheme from 'config/theme';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authenticate } from 'shared/reducers/authenticationSlice';
import { fetchDevices, updateDevicesWithGroup } from 'shared/reducers/devicesSlice';
import { fetchGroups } from 'shared/reducers/groupSlice';
import { fetchUsers } from 'shared/reducers/usersSlice';
import { fetchListOfDeviceContents } from 'shared/reducers/workspaceSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '90vh'
    },
    logo: {
      height: 40
    }
  })
);

const LoadingWorkspace = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const configTheme = useConfigTheme();

  useEffect(() => {
    const load = async () => {
      dispatch(fetchUsers()); // No need to wait for this

      await Promise.all([
        dispatch(authenticate()),
        dispatch(fetchDevices()),
        (async () => {
          await dispatch(fetchGroups());
          await dispatch(fetchListOfDeviceContents());
        })()
      ]);
      await dispatch(updateDevicesWithGroup());
    };

    load();
  }, [dispatch]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item sm={4}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <img src={configTheme.logoSmall} alt="" className={classes.logo} />
          </Grid>
          <Grid item>
            <Typography component="h6">{t('loading')}</Typography>
          </Grid>
        </Grid>
        <LinearProgress />
      </Grid>
    </Grid>
  );
};

export default LoadingWorkspace;
