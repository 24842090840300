import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'shared/widgets/loading';
import AutoOrderForm from './AutoOrderForm';
import { IRootState } from 'config/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutoOrders } from 'shared/reducers/autoOrdersSlice';

const AutoOrderConfiguration = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;
  const loading = useSelector(({ autoOrder }: IRootState) => autoOrder.loading);
  const groupAutoOrder = useSelector(({ autoOrder }: IRootState) =>
    autoOrder.groupAutoOrders.find(g => g.group_id === id)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNew) {
      // fetch groupAutoOrder
      dispatch(fetchAutoOrders());
    }
  }, [isNew, id, dispatch]);

  if (!isNew && loading) {
    return <Loading />;
  }

  return (
    <Box p={1}>
      <Paper elevation={1}>
        <AutoOrderForm
          editGroupAutoOrder={isNew ? undefined : groupAutoOrder}
          onClose={() => {
            history.push('/auto-orders');
          }}
        />
      </Paper>
    </Box>
  );
};

export default AutoOrderConfiguration;
