import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ILabelValueOption } from 'shared/utils/select-utils';
import SingleAutoComplete, { ISingleAutoCompleteProps } from './singleAutocomplete';
import { useTranslation } from 'react-i18next';
import combineValidates from './combineValidates';
import validateRequired from './validateRequired';
import { IGroup } from 'shared/model/group.model';

type GroupId = string;

interface SelectGroupProps
  extends Omit<ISingleAutoCompleteProps<GroupId>, 'options' | 'defaultValue'> {
  name: string;
  defaultValue?: GroupId;
  required?: boolean;
  filterGroups?: (groups: IGroup[]) => IGroup[];
}

const SelectGroup = ({
  name,
  required,
  defaultValue,
  filterGroups = x => x,
  ...rest
}: SelectGroupProps) => {
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const filteredGroups = useMemo(() => filterGroups(groups), [groups, filterGroups]);
  const groupOptions: ILabelValueOption<GroupId>[] = useMemo(
    () =>
      filteredGroups.map(group => ({
        label: group.group_name,
        value: group.group_id
      })),
    [filteredGroups]
  );

  return (
    <SingleAutoComplete
      name={name}
      options={groupOptions}
      label={t('plant')}
      defaultValue={groupOptions.find(option => option.value === defaultValue)}
      {...rest}
      validate={combineValidates(required && validateRequired, rest.validate)}
    />
  );
};

export default SelectGroup;
