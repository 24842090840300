import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { IUser } from 'shared/model/user.model';
import { TextField, TextFieldProps } from '@material-ui/core';
import { displayUser } from 'shared/utils/user-utils';

type UserId = string;

type SelectUsersProps = Omit<Partial<AutocompleteProps<IUser>>, 'options'> & {
  multiple?: true;
  withSelectAll?: boolean;
  value?: UserId[];
  defaultValue?: UserId[];
  onChange: (userIds: UserId[]) => void;
  textFieldProps?: TextFieldProps;
  inputProps?: TextFieldProps['inputProps'];
};

function SelectUsers({
  withSelectAll = false,
  disableCloseOnSelect = true,
  multiple = true,
  defaultValue,
  onChange,
  value,
  textFieldProps = {},
  inputProps = {},
  ...rest
}: SelectUsersProps) {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const users = useSelector(({ users }: IRootState) => users.users);
  const getOptionLabel = useCallback((user: IUser) => displayUser(user), []);

  const defaultUsers = useMemo(() => {
    if (defaultValue) {
      return users.filter(user => defaultValue.includes(user.idUser));
    }
    return [];
  }, [defaultValue, users]);

  const valueUsers = useMemo(() => {
    if (value) {
      return users.filter(user => value.includes(user.idUser));
    }
    return [];
  }, [value, users]);

  return (
    <Box>
      <Autocomplete
        options={users}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultUsers}
        value={valueUsers}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        renderInput={params => (
          <TextField
            placeholder={t('select_user', { count: multiple ? 2 : 1 })}
            {...params}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              ...inputProps,
              autoComplete: 'disabled',
              'data-1p-ignore': true
            }}
          />
        )}
        onChange={(event, users) => {
          setSelectedUsers(users);
          onChange(users.map(u => u.idUser));
        }}
        {...rest}
      />

      {withSelectAll && (
        <Box display="flex" justifyContent="flex-end">
          <Checkbox
            checked={users.every(user =>
              selectedUsers.some(selectedUser => selectedUser.idUser === user.idUser)
            )}
            onChange={(event, checked) => {
              const newSelectedUsers = checked ? users : [];
              setSelectedUsers(newSelectedUsers);
              onChange(newSelectedUsers.map(u => u.idUser));
            }}
            size="small"
          />
        </Box>
      )}
    </Box>
  );
}

export default SelectUsers;
