import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import LinkIcon from '@material-ui/icons/Link';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { combineDevices } from 'shared/reducers/devicesSlice';
import { usePrevious } from 'shared/utils/react-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '400px',
        minHeight: '120px'
      }
    }
  })
);

export interface ICombineForm {
  name: string;
}

interface ICombineBtnProps {
  devices: IDevice[];
  onSuccess: () => void;
}

const CombineBtn = (props: ICombineBtnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const updateSuccess = useSelector(({ devices }: IRootState) => devices.updateSuccess);
  const updating = useSelector(({ devices }: IRootState) => devices.updating);
  const previousUpdating = usePrevious(updating);
  const { register, handleSubmit, errors } = useForm<ICombineForm>({
    defaultValues: {
      name: ''
    }
  });

  const { devices, onSuccess } = props;
  const [open, setOpen] = useState(false);

  const sameFarmName = devices.every(item => item.farm_name === devices[0].farm_name);
  const noneIsCombine = devices.every(item => !item.is_combined);
  const noneHasCombinedDevice = devices.every(item => item?.combinedDevice?.length === 0);

  const canCombine = sameFarmName && noneIsCombine && noneHasCombinedDevice;

  useEffect(() => {
    if (canCombine && updateSuccess && open && previousUpdating) {
      handleClose();
      onSuccess();
    }
  }, [canCombine, updateSuccess, dispatch, open, previousUpdating, onSuccess]);

  if (!canCombine) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = handleSubmit(toCreate => {
    dispatch(combineDevices(toCreate.name, devices));
  });

  return (
    <>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <form onSubmit={onSubmit} autoComplete="off">
          <DialogTitle>{t('combine')}</DialogTitle>
          <DialogContent className={classes.content} dividers>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t('name')}
              fullWidth
              name="name"
              disabled={updating}
              inputRef={register({
                required: <Trans i18nKey="required_field">Required Field</Trans>
              })}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="default"
              startIcon={<CancelIcon />}
              disabled={updating}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<DoneIcon />}
              disabled={updating}
            >
              {t('save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Button variant="contained" color="primary" onClick={handleOpen} title={t('combine_tooltip')}>
        <LinkIcon />
      </Button>
    </>
  );
};

export default CombineBtn;
