import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

export interface ISelectMinMaxProps {
  isActive: boolean;
}

const SelectMinMax = (props: ISelectMinMaxProps) => {
  const { isActive } = props;
  const { t } = useTranslation();
  const form = useFormContext();

  const minValue = form.watch<string, string>('min_value');
  const maxValue = form.watch<string, string>('max_value');
  const datatype = form.watch<string, string>('data_type');

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          id="min_value"
          fullWidth
          label={t('min_val')}
          type="number"
          name="min_value"
          inputProps={{
            step: 'any',
            min: 0
          }}
          inputRef={form.register({
            validate: value => {
              if (isActive) {
                if (value.length === 0 && !maxValue) {
                  return <Trans i18nKey="minOrMaxValue">Required Field</Trans>;
                } else if (value.length > 0 && maxValue) {
                  if (Number(value) >= maxValue) {
                    return <Trans i18nKey="minValueLessMaxValue">Required Field</Trans>;
                  }
                } else if (value < 0) {
                  return <Trans i18nKey="positive_integer_value">Required Field</Trans>;
                }
                if (
                  value?.length > 0 &&
                  (datatype.value === 'level_t' || datatype.value === 'missingWeight')
                ) {
                  if (!/^\d+(\.\d{1})?$/.test(value))
                    return <Trans i18nKey="alert_form_one_decimal_error">Required Field</Trans>;
                }
                if (
                  value?.length > 0 &&
                  datatype.value !== 'level_t' &&
                  datatype.value !== 'missingWeight'
                ) {
                  if (!Number.isInteger(Number(value))) {
                    return (
                      <Trans i18nKey="alert_form_min_or_max_integer_error">Required Field</Trans>
                    );
                  }
                }
              }
              return true;
            }
          })}
          error={form.errors.min_value ? true : false}
          helperText={form.errors.min_value && form.errors.min_value.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          margin="dense"
          id="max_value"
          fullWidth
          label={t('max_val')}
          type="number"
          name="max_value"
          inputProps={{
            step: 'any',
            min: 0
          }}
          inputRef={form.register({
            validate: value => {
              if (isActive) {
                if (value.length === 0 && !minValue) {
                  return <Trans i18nKey="minOrMaxValue">Required Field</Trans>;
                } else if (value.length > 0 && minValue) {
                  if (Number(value) <= minValue) {
                    return <Trans i18nKey="minValueLessMaxValue">minValueLessMaxValue</Trans>;
                  }
                } else if (value < 0) {
                  return <Trans i18nKey="minValueLessMaxValue">Required Field</Trans>;
                } else if (value < 0) {
                  return <Trans i18nKey="positive_integer_value">Required Field</Trans>;
                }
                if (
                  value?.length > 0 &&
                  (datatype.value === 'level_t' || datatype.value === 'missingWeight')
                ) {
                  if (!/^\d+(\.\d{1})?$/.test(value))
                    return <Trans i18nKey="alert_form_one_decimal_error">Required Field</Trans>;
                }
                if (
                  value?.length > 0 &&
                  datatype.value !== 'level_t' &&
                  datatype.value !== 'missingWeight'
                ) {
                  if (!Number.isInteger(Number(value))) {
                    return (
                      <Trans i18nKey="alert_form_min_or_max_integer_error">Required Field</Trans>
                    );
                  }
                }
              }
              return true;
            }
          })}
          error={form.errors.max_value ? true : false}
          helperText={form.errors.max_value?.message}
        />
      </Grid>
    </Grid>
  );
};

export default SelectMinMax;
