import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

/**
 * Display step only when active.
 * Used for form steps.
 */
function StepContainer({
  isActiveStep,
  ...rest
}: BoxProps & {
  isActiveStep: boolean;
}) {
  return <Box display={isActiveStep ? 'block' : 'none'} {...rest} />;
}

export default StepContainer;
