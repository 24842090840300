import React, { useCallback, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, FormLabel, Grid } from '@material-ui/core';
import SelectGroup from 'shared/widgets/form/selectGroup';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoOrderFormResponse } from './AutoOrderForm';
import SelectUsers from 'shared/widgets/form/selectUsers';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import validateRequired from 'shared/widgets/form/validateRequired';
import { GroupAutoOrder } from 'shared/model/autoOrder.model';
import { IGroup } from 'shared/model/group.model';
import combineValidates from 'shared/widgets/form/combineValidates';
import StepContainer from 'shared/widgets/form/StepContainer';
import EditShiptoSoldto from './EditShiptoSoldto';

const AutoOrderFormPlantSelect = ({
  editGroupAutoOrder,
  isNew,
  isActiveStep
}: {
  editGroupAutoOrder?: GroupAutoOrder;
  isNew: boolean;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue, control, watch, register, errors, triggerValidation } =
    useFormContext<AutoOrderFormResponse>();
  const groupId = watch('groupId')?.value;
  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);

  const emailRecipients = watch('emailRecipients');
  useEffect(() => {
    register('emailRecipients', {
      validate: combineValidates(isActiveStep && validateRequired)
    });
  }, [isActiveStep, register]);

  useEffect(() => {
    if (editGroupAutoOrder) {
      setValue('groupId', { value: editGroupAutoOrder.group_id });
    }
  }, [editGroupAutoOrder, setValue]);

  const filterSelectableGroups = useCallback(
    (groups: IGroup[]) => {
      return groups.filter(group => {
        const alreadyHasAutoOrder = groupAutoOrders.some(
          autoOrder => autoOrder.group_id === group.group_id
        );
        return group.is_poi && (!isNew || !alreadyHasAutoOrder);
      });
    },
    [groupAutoOrders, isNew]
  );

  return (
    <StepContainer isActiveStep={isActiveStep}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <SelectGroup
            name={'groupId'}
            required={isNew}
            disabled={!isNew}
            filterGroups={filterSelectableGroups}
            defaultValue={editGroupAutoOrder?.group_id}
          />
          {/* Edit shipTo and soldTo */}
          {groupId && <EditShiptoSoldto groupId={groupId} isActiveStep={isActiveStep} />}
          <Box p={2} />

          <FormLabel>{t('user_notification_when_issue')}</FormLabel>
          <SelectUsers
            value={emailRecipients}
            textFieldProps={{
              error: Boolean(errors.emailRecipients),
              helperText: errors.emailRecipients?.message
            }}
            onChange={userIds => {
              setValue('emailRecipients', userIds);
              triggerValidation('emailRecipients');
            }}
          />
          {/* Checkbox for email notification */}
          {groupId && (
            <FormControlLabel
              label={t('receive_email_on_each_order')}
              control={
                <Controller
                  name="notifyOnEveryOrder"
                  control={control}
                  defaultValue={true}
                  as={<Checkbox color="primary" />}
                />
              }
            />
          )}
        </Grid>
      </Grid>
    </StepContainer>
  );
};

export default AutoOrderFormPlantSelect;
