import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { supportedLangs } from 'shared/utils/lang-utils';
import { isProd } from './env';

// for all options read: https://www.i18next.com/overview/configuration-options
const initOptions: InitOptions = {
  debug: !isProd(),
  fallbackLng: 'en',
  supportedLngs: supportedLangs,
  load: 'currentOnly',
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  backend: {
    loadPath: '/locales/{{lng}}.json'
  }
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init(initOptions)
  .then(_ => _);

export default i18n;
