import React, { useMemo } from 'react';
import { Box, createStyles, makeStyles, Switch, Theme } from '@material-ui/core';
import DataTable from 'shared/widgets/dataTable';
import Paper from '@material-ui/core/Paper';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import clsx from 'clsx';
import PrivateComponent from 'shared/auth/privateComponent';
import { DeviceAutoOrder, GroupAutoOrder } from 'shared/model/autoOrder.model';
import { useDispatch, useSelector } from 'react-redux';
import { activateDeviceAutoOrder } from 'shared/reducers/autoOrdersSlice';
import { IRootState } from 'config/store';

export interface AutoOrderDetailsProps {
  data: GroupAutoOrder;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    title: {
      fontWeight: 'bold',
      '&>svg': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
      }
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    }
  })
);

const AutoOrderDetails = (props: AutoOrderDetailsProps) => {
  const { data } = props;
  const classes = useStyles();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);
  const autoOrders = data.device_auto_orders;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns: IDataTableColumn<DeviceAutoOrder>[] = useMemo(() => {
    const handleStatusChange = (deviceAutoOrder: DeviceAutoOrder) => () => {
      dispatch(
        activateDeviceAutoOrder({ ...deviceAutoOrder, is_active: !deviceAutoOrder.is_active })
      );
    };

    return [
      {
        id: 'siloName',
        selector: row => row.device_name,
        name: t('silo_plural'),
        grow: 2
      },
      {
        selector: row => row.capa_max,
        name: t('capacity'),
        sortable: true,
        format: row => t('number_workspace_filling_unit', { value: row.capa_max }),
        grow: 1
      },
      {
        selector: row => row.device_content,
        name: t('device_content'),
        sortable: true,
        grow: 3
      },
      {
        selector: row => row.device_content_reference,
        name: t('reference'),
        sortable: true,
        grow: 2
      },
      {
        selector: row => row.last_autoorder_date,
        name: t('last_trigger'),
        sortable: true,
        grow: 3,
        format: row => {
          if (row.last_autoorder_date) {
            const humanize = row.last_autoorder_date.fromNow();
            const dateStr = formatDate(row.last_autoorder_date, APP_TIMESTAMP_FORMAT);
            return `${humanize} - ${dateStr}`;
          }
          return null;
        }
      },
      {
        selector: row => row.is_active,
        name: t('active'),
        sortable: true,
        grow: 1,
        format: row => {
          return (
            <div style={{ textAlign: 'right' }}>
              <PrivateComponent
                resource="AutoOrderActive"
                operation={['UPDATE']}
                otherwise={<Switch checked={row.is_active} disabled color="primary" />}
              >
                <Switch
                  checked={row.is_active}
                  color="primary"
                  onChange={handleStatusChange(row)}
                />
              </PrivateComponent>
            </div>
          );
        }
      }
    ];
  }, [t, dispatch]);

  if (!data) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })} p={2} pl={8}>
      <Box>
        <Box minHeight="100px">
          <Paper elevation={4}>
            <Box p={1}>
              <DataTable
                noHeader
                columns={columns}
                data={autoOrders}
                defaultSortField="siloName"
                defaultSortAsc={false}
                paginationPerPage={20}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default AutoOrderDetails;
