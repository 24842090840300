import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import { IInviteUser, inviteUser, IUpdateUser, updateUser } from 'shared/reducers/usersSlice';
import { isValidEmail } from 'shared/utils/data-utils';
import { usePrevious } from 'shared/utils/react-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import { getLangOptions } from '../../../shared/utils/lang-utils';
import { IWorkspaceRole } from 'shared/model/workspace.model';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
interface ILabelValueOptionRole<T> extends ILabelValueOption<T> {
  hierarchy?: number;
  global?: number;
}
interface IFormResponse {
  email: string;
  role: ILabelValueOptionRole<string>;
  lang: ILabelValueOption<string>;
  groups: ILabelValueOption<string>[];
  first_name: string;
  last_name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {},
    content: {
      minHeight: '200px',
      minWidth: '350px'
    },
    email: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2)
    },
    autoComplete: {
      marginBottom: theme.spacing(2)
    }
  })
);

interface AddOrEditUserDialogProps {
  user?: IUser;
  onSuccess?: Function;
}

const AddOrEditUserDialog = (props: AddOrEditUserDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const inviteSuccess = useSelector(({ users }: IRootState) => users.inviteSuccess);
  const inviting = useSelector(({ users }: IRootState) => users.inviting);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const updating = useSelector(({ users }: IRootState) => users.inviting);
  const updateSuccess = useSelector(({ users }: IRootState) => users.updateSuccess);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const availableRoles = useSelector(({ workspace }: IRootState) => workspace.availableRoles);
  const previousInviteSuccess = usePrevious(inviteSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const [isAccess, setIsAccess] = useState(false);
  const [userNoGroupWarning, setUserNoGroupWarning] = useState(false);
  const { user, onSuccess } = props;
  const isNew = user ? false : true;
  const roleOptions = availableRoles.map((role: IWorkspaceRole) => {
    return {
      label: role.display_name,
      value: role.idRole,
      global: role.global,
      hierarchy: role.hierarchy
    };
  });
  const langOptions = getLangOptions();

  const groupOptions = groups.map(group => ({
    label: group.group_name,
    value: group.group_id
  }));

  const form = useForm<IFormResponse>({
    defaultValues: {
      role: user
        ? roleOptions.find(opt => opt.value === user?.workspace_role.idRole)
        : { label: '', value: '' },
      email: user ? user.email : '',
      first_name: user ? user.first_name : '',
      last_name: user ? user.last_name : '',
      lang: user
        ? langOptions.find(opt => opt.value === user.preferred_language)
        : langOptions.find(opt => opt.value === i18n.resolvedLanguage),
      groups: user
        ? groupOptions.filter(go =>
            user.group_memberships.map(gm => gm.group.group_id).includes(go.value)
          )
        : []
    }
  });

  const userRole = form.watch('role');
  const access = form.watch('groups');

  useEffect(() => {
    if (userNoGroupWarning && access?.length) setUserNoGroupWarning(false);
  }, [access, userNoGroupWarning]);

  useEffect(() => {
    if (roleOptions.find(r => r.value === userRole.value)?.global) {
      setIsAccess(false);
    } else {
      setIsAccess(true);
    }
  }, [userRole, roleOptions]);
  const toggleDialog = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (previousInviteSuccess !== inviteSuccess && inviteSuccess === true && open) {
      setOpen(false);
    }
  }, [inviteSuccess, open, previousInviteSuccess]);

  useEffect(() => {
    if (previousUpdateSuccess !== updateSuccess && updateSuccess === true && open) {
      setOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [onSuccess, open, previousUpdateSuccess, updateSuccess]);

  const onSubmit = form.handleSubmit((values: IFormResponse) => {
    if (values?.groups?.length === 0 && !userNoGroupWarning && isAccess) {
      setUserNoGroupWarning(true);
      return;
    }
    const invitation: IInviteUser = {
      email: values.email,
      idRole: values.role.value,
      group_ids: isAccess ? values.groups.map(g => g.value) : [],
      lang: values.lang.value,
      first_name: values.first_name,
      last_name: values.last_name
    };

    const update: IUpdateUser = {
      preferred_language: values.lang.value,
      idRole: values.role.value,
      first_name: values.first_name,
      last_name: values.last_name
    };

    if (isNew) {
      dispatch(inviteUser(invitation));
    } else if (user) {
      const groupsToAdd = isAccess
        ? values.groups
            .map(aGroup => ({ group_id: aGroup.value }))
            .filter(
              groupToAdd =>
                !user.group_memberships.map(gm => gm.group.group_id).includes(groupToAdd.group_id)
            )
        : [];
      const groupsToDelete = isAccess
        ? user.group_memberships.filter(
            gm => !values.groups.map(g => g.value).includes(gm.group.group_id)
          )
        : user.group_memberships;
      dispatch(updateUser({ infosForUpdate: update, user, groupsToAdd, groupsToDelete }));
    }
  });

  const title = isNew ? t('users_actionbar') : t('edit_account');

  return (
    <>
      <Tooltip title={title}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          {isNew ? <PersonAddIcon /> : <EditIcon />}
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog} className={classes.dialog}>
        <FormContext {...form}>
          <form onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.content} dividers>
              <TextField
                autoFocus
                className={classes.email}
                margin="normal"
                fullWidth
                label={t('email_address')}
                name="email"
                autoComplete="off"
                inputRef={form.register({
                  validate: value => {
                    if (!value || value.length === 0) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    } else if (!isValidEmail(value)) {
                      return <Trans i18nKey="email_check">invalid email</Trans>;
                    }
                    return true;
                  }
                })}
                disabled={inviting || !isNew}
                error={form.errors.email ? true : false}
                helperText={form.errors.email && form.errors.email.message}
              />
              <TextField
                margin="normal"
                fullWidth
                label={t('firstname')}
                name="first_name"
                disabled={updating}
                autoComplete="off"
                inputRef={form.register()}
                error={form.errors.first_name ? true : false}
                helperText={form.errors.first_name && form.errors.first_name.message}
              />
              <TextField
                margin="normal"
                fullWidth
                label={t('lastname')}
                name="last_name"
                autoComplete="off"
                disabled={updating}
                inputRef={form.register()}
                error={form.errors.last_name ? true : false}
                helperText={form.errors.last_name && form.errors.last_name.message}
              />
              <SingleAutoComplete
                name="role"
                label={t('role')}
                options={roleOptions}
                defaultValue={roleOptions.find(opt => opt.value === user?.workspace_role.idRole)}
                disableClearable
                disabled={updating}
                validate={(value: ILabelValueOption<string>) => {
                  if (!value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />

              {isAccess && (
                <MultipleAutoComplete
                  label={t('add_group')}
                  name="groups"
                  options={groupOptions}
                  defaultValue={groupOptions.filter(go =>
                    user?.group_memberships.map(gm => gm.group.group_id).includes(go.value)
                  )}
                  className={classes.autoComplete}
                  disabled={updating}
                  helperText={
                    userNoGroupWarning ? (
                      <span style={{ color: '#db7d55' }}>{t('user_form_userNoGroupWarning')}</span>
                    ) : null
                  }
                />
              )}
              <SingleAutoComplete
                options={langOptions}
                defaultValue={
                  langOptions.find(opt => opt.value === user?.preferred_language) ??
                  langOptions.find(opt => opt.value === i18n.resolvedLanguage)
                }
                disableClearable
                label={t('lang')}
                disabled={updating}
                name="lang"
                helperText={t('lang_invitation_helper_text')}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} startIcon={<CancelIcon />} disabled={updating}>
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={inviting || updating}
              >
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default AddOrEditUserDialog;
