import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import TextField, { StandardTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { ILabelValueOption } from 'shared/utils/select-utils';

export interface IMultipleAutoCompleteProps<T> extends StandardTextFieldProps {
  name: string;
  options: ILabelValueOption<T>[];
  fixedOptions?: ILabelValueOption<T>[];
  defaultValue?: any[];
  required?: boolean;
  className?: string;
  validate?: Validate;
  onSelectionChange?: Function;
  limitTags?: number;
  disabled?: boolean;
  checkboxes?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultipleAutoComplete<T>(props: IMultipleAutoCompleteProps<T>) {
  const {
    name,
    options,
    required = false,
    className,
    defaultValue,
    validate,
    onSelectionChange,
    limitTags = 3,
    disabled,
    checkboxes = false,
    fixedOptions,
    ...rest
  } = props;
  const form = useFormContext();

  useEffect(() => {
    form.register({ name }, { required, validate });
  }, [form, name, required, validate]);

  const onChange = (e: any, values: ILabelValueOption<T>[]) => {
    form.setValue(name, values);
    if (onSelectionChange) {
      onSelectionChange(values);
    }
  };

  const initialValues = form.getValues()[name]
    ? options.filter(option => form.getValues()[name].some(({ value }) => value === option.value))
    : defaultValue;

  return (
    <Controller
      control={form.control}
      name={name}
      as={props => (
        <Autocomplete<ILabelValueOption<T>>
          multiple
          disableCloseOnSelect
          className={className}
          onChange={onChange}
          defaultValue={initialValues}
          limitTags={limitTags}
          options={options}
          disabled={disabled}
          groupBy={option => option.group as string}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => {
            return value.value === option.value;
          }}
          renderInput={(params: TextFieldProps) => (
            <TextField
              autoComplete="off"
              name={name}
              required={required}
              error={form.errors[name] ? true : false}
              helperText={form.errors[name] && form.errors[name].message}
              disabled={disabled}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled'
              }}
              {...params}
              {...rest}
            />
          )}
          renderOption={
            checkboxes
              ? (option, { selected }) => (
                  <>
                    <Checkbox
                      size="small"
                      color="primary"
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.label}
                  </>
                )
              : undefined
          }
          renderTags={
            fixedOptions
              ? (tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
              : undefined
          }
        />
      )}
    />
  );
}

export default MultipleAutoComplete;
